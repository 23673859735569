 // 

$(document).ready(function(){
    var html = '<div class="floating" id="floating">'+
    '<ul class="floating_list">'+
        '<li class="kefu"><span>咨询服务</span></li>'+
        '<li class="phone"><span>联系我们</span></li>'+
        // '<li class="backtop"></li>'+
    '</ul>'+
    '</div>';
    $('body').append(html);
    $(".backtop").on('click', function (param) { 
        $('html,body').stop(true).animate({scrollTop: 0}, 200);
    })
})